import type { AlertsFormComponent } from '@elseu/sdu-alert-react';
import { TabsProvider, Text } from '@elseu/sdu-titan';
import type { SearchProviderProps, SearchState } from '@elseu/sdu-titan-search';
import {
  ALL_TAB_ID,
  SearchProvider,
  SearchResultsExtension,
  useFilterState,
} from '@elseu/sdu-titan-search';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from '@lingui/macro';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDeepCompareEffect } from 'react-use';

import { useSiteConfig } from '../../../../context/SiteConfigProvider';
import { generateName } from '../../helpers';
import { getAlertFormDefaultValues } from '../../helpers/getAlertFormDefaultValues';
import { getAlertParametersFacetsToSearchFacets } from '../../helpers/getAlertParametersFacetsToSearchFacets';
import { getAlertParametersToAlertFormValues } from '../../helpers/getAlertParametersToAlertFormValues';
import { getAlertSearchFacets } from '../../helpers/getAlertSearchFacets';
import type { AlertParameters } from '../../types/AlertParameters';
import { AlertType } from '../../types/AlertParameters';
import type { AlertFormValues } from './AlertFormSchema';
import { getAlertFormSchema } from './AlertFormSchema';
import { AlertPreviewLink } from './AlertPreviewLink';
import { CustomNameField } from './fields/CustomNameField';
import { FacetField } from './fields/FacetField';
import { TermField } from './fields/TermField';
import { TypeField } from './fields/TypeField';
import { useFacetsWithLabels } from './hooks/useFacetsWithLabels';
import { useAlertStore } from './stores/AlertStore';

interface AlertFormContentProps {
  defaultValues: AlertParameters;
  defaultCustomName: string;
  onChange: AlertsFormComponent['onChange'];
}

/**
 * Component that handles the content of the alert form.
 *
 * @param {AlertFormContentProps} props - The properties of the component.
 * @returns {JSX.Element} The rendered alert form content component.
 */
export const AlertFormContent = ({
  defaultValues: defaultParameters,
  defaultCustomName,
  onChange,
}: AlertFormContentProps) => {
  const { facetsPreset } = useSiteConfig();
  const defaultValues = getAlertParametersToAlertFormValues({
    defaultParameters,
    facetsPreset,
    defaultCustomName,
  });

  /** Create form with default values and validation schema */
  const form = useForm<AlertFormValues>({
    defaultValues,
    // @TODO: Look into type of schema
    resolver: yupResolver(getAlertFormSchema(facetsPreset.tabsFacet, facetsPreset.facets) as any),
    mode: 'onChange',
  });

  const getFacetsWithLabels = useFacetsWithLabels();
  const resetFacetsWithLabels = useAlertStore((state) => state.resetFacetsWithLabels);
  const { formState, watch } = form;

  const { isLoading } = useFilterState();

  const isValid = formState.isValid;
  const formValues = watch();

  useDeepCompareEffect(() => {
    if (isLoading) return;

    const { term, facets, customName } = formValues;
    const facetsWithLabels = getFacetsWithLabels(facets);

    const parameters: AlertParameters = {
      facets: facetsWithLabels,
      term,
      query: term,
      type: defaultParameters.type || AlertType.SEARCH,
      title: term,
    };

    onChange({ parameters, isValid, customName });

    return () => {
      resetFacetsWithLabels();
    };
  }, [formValues, getFacetsWithLabels, isValid, onChange, isLoading]);

  const facets = useMemo(() => getAlertSearchFacets(facetsPreset.facets), [facetsPreset.facets]);

  return (
    <div data-is-loading={isLoading || undefined} data-test-id="alertFormContainer">
      <FormProvider {...form}>
        <Text color="grey80" spaceAfter={4} type="paragraph">
          <Trans>
            Pas je zoekopdracht aan om exact de resultaten te krijgen die je wilt. Probeer de
            zoekopdracht uit door op ‘Voorbeeld zoekresultaat weergeven’ te klikken.
          </Trans>
        </Text>
        <CustomNameField />
        <TermField />
        <TypeField isLoading={isLoading} />
        {facets.map((facet) => (
          <FacetField
            key={facet.name}
            buttonText={facet.label}
            label={facet.label}
            name={facet.name}
            options={facet.options}
            spaceAfter={4}
          />
        ))}
        <AlertPreviewLink />
      </FormProvider>
    </div>
  );
};

/**
 * Component that handles the alert form.
 *
 * @param {AlertsFormComponent} props - The properties of the component.
 * @returns {JSX.Element} The rendered alert form component.
 */
export const AlertForm = ({
  defaultParameters: alertParameters,
  defaultCustomName,
  onChange,
}: AlertsFormComponent) => {
  const { facetsPreset, intelligentSearchCore, searchTabs } = useSiteConfig();
  const defaultParameters = getAlertFormDefaultValues(facetsPreset.tabsFacet, facetsPreset.facets);

  const term = (alertParameters.term || defaultParameters.term) as AlertParameters['term'];
  const facets = (alertParameters.facets || defaultParameters.facets) as AlertParameters['facets'];

  const tabsFacet = facetsPreset.tabsFacet;
  const defaultTabId = ALL_TAB_ID;

  const searchFacets = useMemo(
    () => getAlertParametersFacetsToSearchFacets(facets, facetsPreset),
    [facets, facetsPreset],
  );

  const customName = useMemo(
    () => defaultCustomName || generateName(null, alertParameters),
    [defaultCustomName, alertParameters],
  );

  const [state, setState] = useState<Partial<SearchState>>({});

  const tabsState = (state[facetsPreset.tabsFacet] as string[] | undefined) ?? [];

  const searchProviderProps = useMemo(
    (): SearchProviderProps => ({
      onStateChange: setState,
      state: {
        /** Reset query filter because for alerting we just want all options */
        query: '',
        mode: 'browse',
      },
      scope: {
        label: null,
      },
      facets: {
        ...searchFacets,
        /** Reset year filter because for alerting this field is not needed */
        ro_year: { reset: true },
      },
      params: {
        intelligentSearchCore,
        from: 0,
        /** Reset size filter because for alerting we don't need the actual responses */
        size: 0,
      },
      tabs: searchTabs,
      searchResultsExtensionFragment: SearchResultsExtension,
    }),
    [intelligentSearchCore, searchFacets, searchTabs],
  );

  const activeTab = searchTabs.find((tab) => {
    return tabsState.some((item) => tab.keys.includes(item));
  });

  const activeTabId = activeTab?.id ?? defaultTabId;

  const setActiveTabId = useCallback(
    (id: string | null) => {
      const tab = searchTabs.find((tab) => tab.id === id);

      setState((state) => {
        return {
          ...state,
          [tabsFacet]: tab ? tab.keys : [],
        };
      });
    },
    [searchTabs, tabsFacet],
  );

  return (
    <TabsProvider activeId={activeTabId} onActiveIdChange={setActiveTabId}>
      <SearchProvider loadOnInitial={true} {...searchProviderProps}>
        <AlertFormContent
          defaultCustomName={customName}
          defaultValues={{ type: AlertType.SEARCH, term, facets, ...alertParameters }}
          onChange={onChange}
        />
      </SearchProvider>
    </TabsProvider>
  );
};
