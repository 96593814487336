import { checkGenialAccess } from './checkGenialAccess';
function mapPingProfile(idTokenClaims) {
    return {
        id: idTokenClaims.sub,
        sub: idTokenClaims.sub,
        sn: idTokenClaims.sn,
        givenName: idTokenClaims.givenName,
        name: idTokenClaims.cn,
        cn: idTokenClaims.cn,
        email: idTokenClaims.login,
        login: idTokenClaims.login,
        hasGenialAccess: false, // will be set later in session callback. role array is not part of the idTokenClaims
    };
}
function mapRechtsordeProfile(idTokenClaims) {
    let sn = idTokenClaims.sn;
    if (idTokenClaims.given_name) {
        sn = sn.replace(idTokenClaims.given_name, '').trim();
    }
    return {
        id: idTokenClaims.sub,
        sub: idTokenClaims.sub,
        sn,
        givenName: idTokenClaims.given_name,
        name: idTokenClaims.cn,
        cn: idTokenClaims.cn,
        email: idTokenClaims.email,
        login: idTokenClaims.email,
        userRole: idTokenClaims.role,
        readingListAutocompleteEnabled: idTokenClaims.readingListAutocompleteEnabled,
        hasGenialAccess: checkGenialAccess(idTokenClaims),
    };
}
export const mappings = {
    ping: mapPingProfile,
    rechtsorde: mapRechtsordeProfile,
};
