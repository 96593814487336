import type {
  ApolloClient,
  ApolloError,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import type { Locale } from '@lingui/core';
import { i18n } from '@lingui/core';
import { I18nProvider, useLingui } from '@lingui/react';
import type { ReactElement } from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';

import { ActiveItemInViewProvider } from '../components/ActiveItemInViewProvider/ActiveItemInViewProvider';
import type { FallbackRender } from '../components/ErrorPage/ErrorBoundary';
import type { ContentPart } from '../generated/content/graphql';
import { useContentClient } from '../hooks/useContentClient';
import { dynamicActivate } from '../translations/utils';
import type { AccessToken } from '../types/AccessToken';
import type { ContentRenderingUrls } from '../types/ContentRenderingUrls';
import { ContentRoutingProvider } from './ContentRoutingProvider';

export interface ContentPartPluginPayload {
  documentId: string;
  contentType?: string;
}

export interface ContentPartPluginElement {
  (payload: ContentPartPluginPayload): ReactElement | null;
}

export interface FollowPluginData {
  title: string;
  urls: ContentRenderingUrls;
  canHavePersonalisationFeatures?: boolean;
  referenceId?: string;
}

export interface FollowPlugin {
  isShown: (data: FollowPluginData) => boolean;
  render: (data: FollowPluginData) => null | ReactElement;
}

export interface DockItemContentData {
  type?: string;
  activeContentPart?: string;
  activeTab?: string;
  contentParts?: ContentPart[];
  swsId?: string;
  swsDocumentTitle?: string;
}
export interface DockItemData {
  isMobile: boolean;
  data: DockItemContentData;
  canHavePersonalisationFeatures?: boolean;
}

export type DockItemType = {
  render: (data: DockItemData) => React.ReactElement | React.ReactElement[] | null;
  isShown: (data: DockItemData) => boolean;
};
export type DockItem = {
  order?: number;
  iconItem?: DockItemType;
  menuItem?: DockItemType;
  components?: DockItemType;
};

export type DockData = {
  type?: string;
};
export type Dock = (data: DockData) => React.ReactElement | React.ReactElement[] | null;

export type ContentRenderingLinkResolver = (link: HTMLAnchorElement) => (() => void) | void;

interface SsrConfig {
  main?: boolean;
  contentParts?: number | boolean;
  footNotes?: boolean;
}

type ErrorComponentType = React.FC<{
  statusCode?: number;
  error?: ApolloError;
  forcedErrorState?: number;
}>;

interface ContentRenderingBaseProps {
  /** Site name key */
  siteName: string;
  /** GraphQL Endpoint url */
  graphqlUrl: string;
  /** Application Management Service application key */
  applicationKey?: string;
  /** Custom ApolloClient */
  client?: ApolloClient<NormalizedCacheObject>;
  /** Scroll window */
  scrollWindow?: Window | HTMLElement | null;
  /* The bearer accessToken */
  accessToken?: AccessToken;
  /* A custom error component */
  errorComponent?: ErrorComponentType;
  /* A custom error boundary */
  errorBoundary?: React.FC<React.PropsWithChildren<{ fallback?: ReactElement | FallbackRender }>>;
  /* Default hash in the url */
  defaultHash?: string;
  /* Server side render config */
  ssrConfig?: SsrConfig;
  /* Custom fetch implementation, like node-fetch or cross-fetch */
  fetch?: WindowOrWorkerGlobalScope['fetch'];
  /* Array of floating actions to display */
  dockItems?: DockItem[];
  /** The Docks to show underneath content */
  docks?: Dock[];
  /* Send this when printing a pdf */
  isPrinting?: boolean;
  /* Whether or not personalisation features - like reading lists - are shown */
  canHavePersonalisationFeatures?: boolean;
  /* Whether or not allow for downloading a full edition pdf of a magazine edition */
  hasMagazineEditionPdfDownload?: boolean;
  /* Feature flag to enable full external source viewing */
  hasFullExternalSource?: boolean;
  /* Feature flag to enable links to original sources */
  hasOriginalSourceLinks?: boolean;
  /** Renders a button to add an item to a readingList */
  renderReadingListButton?: (props: { itemReference: string; title: string }) => React.ReactNode;
  /* Dynamic feature flags to prevent breaking changes for every flag change  */
  featureFlags?: Record<string, boolean>;
  /** User subscription roles for the applications that use this in x-auth-role header. */
  roles?: string[] | string | null;
  /** User subscription sub for the applications that use this in x-auth-sub header. */
  sub?: string;
}
export interface ContentRenderingContextProps extends ContentRenderingBaseProps {
  pmtToolBaseUrl: string;
  urls: ContentRenderingUrls;
  contentPartPlugins: ContentPartPluginElement[];
  dateFormatFn: (value: string | Date, format?: Intl.DateTimeFormatOptions) => string;
  numberFormatFn: (value: number, format?: Intl.NumberFormatOptions) => string;
}
export interface ContentRenderingProviderProps extends ContentRenderingBaseProps {
  /* Internal site urls to resolve links with */
  urls?: Partial<ContentRenderingUrls> & Required<Pick<ContentRenderingUrls, 'contentUrl'>>;
  /* Urls to resolve links with for PmtTool tools */
  pmtToolBaseUrl?: string;
  /* Enable debug functionalities */
  debug?: boolean;
  /* Apollo initial state from the cache */
  apolloInitialState?: NormalizedCacheObject;
  /* Register cache function from apollo */
  apolloRegisterCache?: (cache: InMemoryCache) => void;
  /** Has an external internationalization provider to handle translations (lingui) */
  hasExternalI18nProvider?: boolean;
  /* Array of content part plugins */
  contentPartPlugins?: ContentPartPluginElement[];
  /** I18n utilities */
  locale?: Locale;
}

const defaultUrls: ContentRenderingUrls = {
  alertsUrl: '/notificaties',
  contentUrl: '/content',
  editionUrl: '/tijdschriften/:magazineId/:editionId',
  magazineUrl: '/tijdschriften/:magazineId',
  magazinesUrl: '/tijdschriften',
  pnNavigationUrl: '/overzicht/:slug/toc/:documentKey/:subId',
  readingListsUrl: '/leeslijsten',
  searchUrl: '/zoeken',
  newsOverviewUrl: '/nieuws',
  newsSourceUrl: '/nieuws/:sourceId',
};

export const ContentRenderingContext = createContext<ContentRenderingContextProps | undefined>(
  {} as ContentRenderingContextProps,
);

export const ContentRenderingConsumer = ContentRenderingContext.Consumer;

export const useContentRendering = () => {
  const context = useContext(ContentRenderingContext);
  if (context === undefined) {
    throw new Error('useContentRendering must be used within a ContentRenderingProvider');
  }
  return context;
};

const I18nProviderWrapper: React.FC<
  React.PropsWithChildren<Pick<ContentRenderingProviderProps, 'hasExternalI18nProvider' | 'locale'>>
> = ({ locale, hasExternalI18nProvider, children }) => {
  useEffect(() => {
    if (!hasExternalI18nProvider) dynamicActivate(locale);
  }, [hasExternalI18nProvider, locale]);
  if (hasExternalI18nProvider) return children;
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

export const ContentRenderingProvider: React.FC<
  React.PropsWithChildren<ContentRenderingProviderProps>
> = ({ children, ...props }) => {
  return (
    <I18nProviderWrapper {...props}>
      <ContentRenderingProviderContext {...props}>{children}</ContentRenderingProviderContext>
    </I18nProviderWrapper>
  );
};

const ContentRenderingProviderContext: React.FC<
  React.PropsWithChildren<ContentRenderingProviderProps>
> = ({
  accessToken,
  apolloInitialState,
  apolloRegisterCache,
  children,
  client: contentRenderingClient,
  debug,
  fetch,
  graphqlUrl,
  roles,
  sub,
  scrollWindow = typeof window !== 'undefined' ? window : null,
  siteName = '',
  urls = defaultUrls,
  pmtToolBaseUrl = '',
  contentPartPlugins = [],
  ...props
}) => {
  const { i18n } = useLingui();
  const client = useContentClient({
    graphqlUrl,
    accessToken,
    roles,
    sub,
    initialState: apolloInitialState,
    registerCache: apolloRegisterCache,
    fetch,
  });

  const context = useMemo<ContentRenderingContextProps>(
    () => ({
      accessToken,
      client: !process.env.JEST_WORKER_ID ? client : contentRenderingClient,
      fetch,
      graphqlUrl,
      scrollWindow,
      siteName,
      urls: { ...defaultUrls, ...urls },
      pmtToolBaseUrl,
      contentPartPlugins,
      dateFormatFn: (value, options) => i18n.date(value, options),
      numberFormatFn: (value, options) => i18n.number(value, options),
      roles,
      sub,
      ...props,
    }),
    [
      accessToken,
      client,
      contentRenderingClient,
      fetch,
      graphqlUrl,
      scrollWindow,
      siteName,
      urls,
      pmtToolBaseUrl,
      contentPartPlugins,
      props,
      i18n,
      roles,
      sub,
    ],
  );

  return (
    <ContentRenderingContext.Provider value={context}>
      <ContentRoutingProvider>
        <ActiveItemInViewProvider debug={debug} scrollWindow={scrollWindow!}>
          {children}
        </ActiveItemInViewProvider>
      </ContentRoutingProvider>
    </ContentRenderingContext.Provider>
  );
};
