import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export var JobStatus;
(function (JobStatus) {
    JobStatus["DELAYED"] = "DELAYED";
    JobStatus["FAILED"] = "FAILED";
    JobStatus["PAUSED"] = "PAUSED";
    JobStatus["PROCESSING"] = "PROCESSING";
    JobStatus["QUEUED"] = "QUEUED";
    JobStatus["STUCK"] = "STUCK";
    JobStatus["SUCCESS"] = "SUCCESS";
})(JobStatus || (JobStatus = {}));
export var TradeDirection;
(function (TradeDirection) {
    TradeDirection["E"] = "E";
    TradeDirection["I"] = "I";
})(TradeDirection || (TradeDirection = {}));
export const CreateDocumentDocument = gql `
    mutation createDocument($input: DocumentInput!) {
  createDocument(input: $input) {
    jobId
    token
    status
  }
}
    `;
/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateDocumentDocument, options);
}
export const CreateMeasureDocument = gql `
    mutation createMeasure($input: MeasureInput!) {
  createMeasure(input: $input) {
    jobId
    token
    status
  }
}
    `;
/**
 * __useCreateMeasureMutation__
 *
 * To run a mutation, you first call `useCreateMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeasureMutation, { data, loading, error }] = useCreateMeasureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMeasureMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreateMeasureDocument, options);
}
export const CreatePreviewDocumentDocument = gql `
    mutation createPreviewDocument($input: PreviewDocumentInput!) {
  createPreviewDocument(input: $input) {
    jobId
    token
    status
  }
}
    `;
/**
 * __useCreatePreviewDocumentMutation__
 *
 * To run a mutation, you first call `useCreatePreviewDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreviewDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreviewDocumentMutation, { data, loading, error }] = useCreatePreviewDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreviewDocumentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CreatePreviewDocumentDocument, options);
}
export const DocumentDocument = gql `
    subscription document($jobId: String!, $token: String!) {
  documentUpdated(jobId: $jobId, token: $token) {
    jobId
    status
    documentUrl
  }
}
    `;
/**
 * __useDocumentSubscription__
 *
 * To run a query within a React component, call `useDocumentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDocumentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentSubscription({
 *   variables: {
 *      jobId: // value for 'jobId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDocumentSubscription(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSubscription(DocumentDocument, options);
}
