const GENIAL_ROLE = 'genai';
const GENIAL_PERMISSION = 'genial';
/**
 * **NOTE:** This function is only used for Ping users.
 */
function hasGenialRole(role) {
    if (!role)
        return false;
    const roleArray = Array.isArray(role) ? role : [role];
    return roleArray.some((role) => role === null || role === void 0 ? void 0 : role.startsWith(GENIAL_ROLE));
}
/**
 * **NOTE:** This function is only used for Rechtsorde users.
 */
function hasGenialPermission(permissions) {
    if (!permissions)
        return false;
    return permissions.includes(GENIAL_PERMISSION);
}
/**
 * Checks if the user has access to GenIA-L based on their role or permissions.
 * Ping users have access to GenIA-L if they have the GenIA-L role.
 * Rechtsorde users have access to GenIA-L if they have the GenIA-L permission.
 * @param session The user session.
 * @returns True if the user has access to GenIA-L.
 */
export function checkGenialAccess(claims) {
    if ('permissions' in claims && hasGenialPermission(claims.permissions))
        return true;
    if ('role' in claims && hasGenialRole(claims.role))
        return true;
    return false;
}
